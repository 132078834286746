<template>
  <div>
    <img
      :src="getMainLogo"
      width="200"
      alt="PromoShop"
    >
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { IS_CENTROAMERICA } from '@/config/app'

export default {
  computed: {
    getMainLogo() {
      if (this.isDark) {
        // eslint-disable-next-line global-require
        return IS_CENTROAMERICA ? require('@/assets/images/logo/redesign/PROMOSHOP LOGO latinoamerica ok blanco.png') : require('@/assets/images/logo/logo-white.svg')
      }
      // eslint-disable-next-line global-require
      return IS_CENTROAMERICA ? require('@/assets/images/logo/redesign/PROMOSHOP LOGO latinoamerica ok.png') : require('@/assets/images/logo/logo.svg')
    },
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')
    return { skin, isDark }
  },
}
</script>
