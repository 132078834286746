<template>
  <b-sidebar
    id="sidebar-send-invoice"
    v-model="openedOutside"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Crear factura
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >

        <!-- From -->
        <b-form-group
          label="RFC"
          label-for="rfc"
        >
          <b-form-input
            id="rfc"
            v-model="sendInvoiceData.rfc"
            trim
            type="text"
            min="12"
            max="13"
            :formatter="formatter"
          />
        </b-form-group>

        <b-form-group
          label="Razón Social"
          label-for="razon_social"
        >
          <b-form-input
            id="razon_social"
            v-model="sendInvoiceData.razon_social"
            trim
            type="text"
            min="0"
            max="300"
            :formatter="formatter"
          />
        </b-form-group>

        <b-form-group
          label="Codigo Postal"
          label-for="codigo_postal"
        >
          <b-form-input
            id="codigo_postal"
            v-model="sendInvoiceData.domicilioFiscal"
            trim
            type="text"
            min="5"
            max="5"
          />
        </b-form-group>

        <b-form-group
          label="Regimen Fiscal"
          label-for="regimenFiscal"
        >
          <v-select
            id="regimenFiscal"
            v-model="sendInvoiceData.regimen_fiscal"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :options="sendInvoiceData.regimenFiscalList"
          />
        </b-form-group>

        <b-form-group
          label="Uso de CFDI"
          label-for="cfdiUse"
        >
          <v-select
            id="cfdiUse"
            v-model="sendInvoiceData.cfdiUse"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :options="sendInvoiceData.cfdiList"
          />
        </b-form-group>

        <b-form-group
          label="Forma de Pago"
          label-for="formaPago"
        >
          <v-select
            id="formaPago"
            v-model="sendInvoiceData.formaPago"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :options="sendInvoiceData.formaPagoList"
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            :disabled="submitting"
            @click="createInvoice"
          >
            <b-spinner
              v-if="submitting"
              small
            />
            Crear
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="$emit('close')"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BSidebar, BForm, BFormGroup, BFormInput, BButton, vSelect, ToastificationContent, BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    openedOutside: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
    }
  },
  setup() {
    const sendInvoiceData = ref({
      rfc: '',
      razon_social: '',
      regimen_fiscal: '',
      cfdiUse: '',
      domicilioFiscal: '',
      formaPago: '',
      cfdiList: [
        { id: 'G01', label: 'G01 - Adquisición de mercancias' },
        { id: 'G02', label: 'G02 - Devoluciones, descuentos o bonificaciones' },
        { id: 'G03', label: 'G03 - Gastos en general' },
        { id: 'I01', label: 'I01 - Construcciones' },
        { id: 'I02', label: 'I02 - Mobilario y equipo de oficina por inversiones' },
        { id: 'I03', label: 'I03 - Equipo de transporte' },
        { id: 'I04', label: 'I04 - Equipo de computo y accesorios' },
        { id: 'I05', label: 'I05 - Dados, troqueles, moldes, matrices y herramental' },
        { id: 'I06', label: 'I06 - Comunicaciones telefónicas' },
        { id: 'I07', label: 'I07 - Comunicaciones satelitales' },
        { id: 'I08', label: 'I08 - Otra maquinaria y equipo' },
        { id: 'D01', label: 'D01 - Honorarios médicos, dentales y gastos hospitalarios.' },
        { id: 'D02', label: 'D02 - Gastos médicos por incapacidad o discapacidad' },
        { id: 'D03', label: 'D03 - Gastos funerales' },
        { id: 'D04', label: 'D04 - Donativos' },
        { id: 'D06', label: 'D06 - Aportaciones voluntarias al SAR' },
        { id: 'D07', label: 'D07 - Primas por seguros de gastos médicos' },
        { id: 'D08', label: 'D08 - Gastos de transportación escolar obligatoria' },
        { id: 'D09', label: 'D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.' },
        { id: 'D10', label: 'D10 - Pagos por servicios educativos (colegiaturas)' },
        { id: 'P01', label: 'P01 - Por definir' },
      ],
      regimenFiscalList: [
        { id: '601', label: '601 - General de Ley Personas Morales' },
        { id: '603', label: '603 - Personas Morales con Fines no Lucrativos' },
        { id: '605', label: '605 - Sueldos y Salarios e Ingresos Asimilados a Salarios' },
        { id: '606', label: '606 - Arrendamiento' },
        { id: '607', label: '607 - Régimen de Enajenación o Adquisición de Bienes' },
        { id: '608', label: '608 - Demás ingresos' },
        { id: '610', label: '610 - Residentes en el Extranjero sin Establecimiento Permanente en México' },
        { id: '611', label: '611 - Ingresos por Dividendos (socios y accionistas)' },
        { id: '612', label: '612 - Personas Físicas con Actividades Empresariales y Profesionales' },
        { id: '614', label: '614 - Ingresos por intereses' },
        { id: '615', label: '615 - Régimen de los ingresos por obtención de premios' },
        { id: '616', label: '616 - Sin obligaciones fiscales' },
        { id: '620', label: '620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
        { id: '621', label: '621 - Incorporación Fiscal' },
        { id: '622', label: '622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
        { id: '623', label: '623 - Opcional para Grupos de Sociedades' },
        { id: '624', label: '624 - Coordinados' },
        { id: '625', label: '625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
        { id: '626', label: '626 - Régimen Simplificado de Confianza' },
      ],
      formaPagoList: [
        { id: '01', label: '01 - Efectivo' },
        { id: '03', label: '03 - Transferencia Electrónica de Fondos' },
        { id: '04', label: '04 - Tarjeta de Crédito' },
        { id: '28', label: '28 - Tarjeta de Débito' },
      ],
    })

    return {
      sendInvoiceData,
    }
  },
  methods: {
    formatter(value) {
      return value.toUpperCase()
    },
    createInvoice() {
      this.submitting = true
      const self = this
      store.dispatch('orders/createInvoice', {
        orderId: this.id,
        rfc: this.sendInvoiceData.rfc,
        cfdiUse: this.sendInvoiceData.cfdiUse.id,
        regimenFiscal: this.sendInvoiceData.regimen_fiscal.id,
        razonSocial: this.sendInvoiceData.razon_social,
        domicilioFiscal: this.sendInvoiceData.domicilioFiscal,
        formaPago: this.sendInvoiceData.formaPago.id,
      }).then(
        response => {
          console.log(response)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'CheckCircleIcon',
              text: 'La factura se ha emitido correctamente',
              variant: 'success',
            },
          },
          {
            position: 'bottom-center',
            timeout: 10000,
          })
          self.submitting = false
          this.$emit('close')
          return Promise.resolve(response)
        },
        error => {
          console.log(error)
          const firstError = error.data.errors[Object.keys(error.data.errors)[0]]
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertCircleIcon',
              text: firstError,
              variant: 'danger',
            },
          },
          {
            position: 'bottom-center',
            timeout: 10000,
          })
          self.submitting = false
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>

<style>

</style>
