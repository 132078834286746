<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        Error obteniendo los datos de la orden
      </h4>
      <div class="alert-body">
        No se ha encontrado una orden con el ID seleccionado. Ingresa a la
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          lista de ordenes
        </b-link>
        para seleccionar otra.
      </div>
    </b-alert>

    <b-row
      v-if="invoiceData"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mb-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper mb-0">
                  <logo />
                </div>
                <div class="invoice-date-wrapper">
                  <h4 class=" mt-2 mb-2 text-left">
                    Estado del pedido
                  </h4>
                  <p class="invoice-date">
                    <template v-if="invoiceData.status === 'paid'">
                      <b-badge
                        pill
                        variant="primary"
                      >
                        Pagado
                      </b-badge>
                    </template>
                    <template v-if="invoiceData.status === 'pending'">
                      <b-badge
                        pill
                        variant="warning"
                      >
                        Sin pago
                      </b-badge>
                    </template>
                    <template v-if="invoiceData.status === 'pending_approval'">
                      <b-badge
                        pill
                        variant="warning"
                      >
                        Pendiente de aprobación
                      </b-badge>
                    </template>
                    <template v-if="invoiceData.status === 'shipped'">
                      <b-badge
                        pill
                        variant="light-success"
                      >
                        Enviado
                      </b-badge>
                    </template>
                    <template v-if="invoiceData.status === 'completed'">
                      <b-badge
                        pill
                        variant="success"
                      >
                        Completada
                      </b-badge>
                    </template>
                    <template v-if="invoiceData.status === 'cancelled'">
                      <b-badge
                        pill
                        variant="danger"
                      >
                        Cancelada
                      </b-badge>
                    </template>
                  </p>
                </div>
                <h6 class="text-left">
                  Números de seguimiento
                </h6>
                <div
                  v-for="shipment in shipments"
                  :key="shipment.id"
                >
                  <p class="invoice-date mb-0">
                    {{ shipment.configuration.company }}: <a href="2797991335">{{ shipment.tracking_number }}</a>
                  </p>
                </div>

              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title mt-1 mb-2">
                  Resumen de Pedido
                  <span class="invoice-number">#{{ invoiceData.number }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title invoice-date-title-2">
                    Fecha de pedido
                  </p>
                  <p class="invoice-date">
                    {{ formatDate(invoiceData.created_at) }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title invoice-date-title-2">
                    Fecha de envio:
                  </p>
                  <p class="invoice-date">
                    {{ shipments.length ? formatDate(shipments[0].created_at) : 'Aún no hay envío asignado' }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            v-if="invoiceData.client"
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h4 class="mb-2">
                  <b>Dirección de envío</b>
                </h4>
                <table>
                  <tbody>
                    <tr>
                      <td class="pr-1">
                        <b>Recibe:</b>
                      </td>
                      <td><span class="font-weight-bold">{{ invoiceData.client.name }}</span></td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <b>Dirección:</b>
                      </td>
                      <td>{{ invoiceData.client.address }}, {{ invoiceData.client.country }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <b>Teléfono:</b>
                      </td>
                      <td>{{ invoiceData.client.phone }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        <b>CP:</b>
                      </td>
                      <td>{{ invoiceData.client.postalcode }}</td>
                    </tr>

                  </tbody>
                </table>

              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h4 class="mb-2">
                    <b>Información de pago</b>
                  </h4>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          <b>Total de pago:</b>
                        </td>
                        <td><span class="font-weight-bold">{{ formatter.format(parseFloat(invoiceData.total.replace(/,/, ''))) }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>Método de pago: </b>
                        </td>
                        <td>{{ paymentDetails[0].status === 'paid' ? paymentDetails.map(payment => payment.methodName).join(', ') : 'No se ha recibido el pago' }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>Fecha de pago: </b>
                        </td>
                        <td>{{ paymentDetails[0].status === 'paid' ? formatDate(paymentDetails[0].updated_at) : 'No se ha recibido el pago' }}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="invoiceData.items"
            :fields="['producto', 'cantidad', 'precio', 'total']"
          >
            <template #cell(producto)="data">
              <b-card-text class="font-weight-bold mb-25">
                <a href="">Ver {{ data.item.name }}</a>
              </b-card-text>
            </template>
            <template #cell(cantidad)="data">
              <b-card-text>
                {{ data.item.quantity }}
              </b-card-text>
            </template>
            <template #cell(precio)="data">
              <b-card-text>
                {{ formatter.format(data.item.price) }}
              </b-card-text>
            </template>
            <template #cell(total)="data">
              <b-card-text>
                {{ formatter.format(data.item.quantity * data.item.price) }}
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              />

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Envío:
                    </p>
                    <p class="invoice-total-amount">
                      {{ getShippingCost }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      IVA:
                    </p>
                    <p class="invoice-total-amount">
                      {{ getIvaCost }}
                    </p>
                  </div>
                  <div
                    v-if="paymentDetails.length > 1"
                    class="invoice-total-item"
                  >
                    <p class="invoice-total-title">
                      Descuento (puntos):
                    </p>
                    <p class="invoice-total-amount">
                      {{ formatter.format(parseFloat(paymentDetails[0].amount)) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      {{ formatter.format(parseFloat(paymentDetails.length > 1 ? paymentDetails[1].amount : invoiceData.total.replace(/,/, ''))) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Nota: </span>
            <span>Puedes solicitar tu factura hasta 30 días después de haber realizado tu compra.</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <div v-if="!invoiceData.isLocalPayment">
            <!-- Button: Send Invoice -->
            <b-button
              v-if="!invoice"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-75"
              block
              @click="showIssueInvoiceSidebar"
            >
              Solicitar Factura
            </b-button>

            <div v-else>
              <!-- Button: Send Invoice -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mb-75"
                block
                :disabled="submitting"
                @click="downloadInvoice"
              >
                <b-spinner
                  v-if="submitting"
                  small
                />
                Descargar factura XML
              </b-button>
              <!-- Button: Send Invoice -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-75"
                block
                @click="downloadInvoicePdf"
              >
                Descargar factura PDF
              </b-button>
            </div>
          </div>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimir ticket
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <invoice-sidebar-send-invoice
      :id="invoiceData.id"
      :opened-outside="issueInvoiceToggle"
      @close="closeIssueInvoiceSidebar"
    />
    <invoice-sidebar-add-payment />

  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BBadge, BSpinner,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/LogoBlack.vue'
import Ripple from 'vue-ripple-directive'
import { API_URL } from '@/config/app'
import InvoiceSidebarSendInvoice from './components/InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarAddPayment from './components/InvoiceSidebarAddPayment.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BSpinner,
    Logo,
    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,
  },
  data() {
    return {
      issueInvoiceToggle: false,
      submitting: false,
    }
  },
  computed: {
    formatter() {
      return new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      })
    },
    getShippingCost() {
      const shipping = this.invoiceData.adjustments_relation.filter(item => item.type === 'shipping')
      return this.formatter.format(shipping.length ? shipping[0].amount : 0.00)
    },
    getIvaCost() {
      const tax = this.invoiceData.adjustments_relation.filter(item => item.type === 'tax')
      return this.formatter.format(tax.length ? tax[0].amount : 0.00)
    },
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})
    const shipments = ref([])
    const shippingAddress = ref({})
    const invoice = ref({})

    store.dispatch('orders/show', { orderId: router.currentRoute.params.id })
      .then(
        response => {
          invoiceData.value = response.orders
          paymentDetails.value = response.payment
          shipments.value = response.shipments
          shippingAddress.value = response.shippingAddress
          invoice.value = response.invoice
        },
        error => {
          if (error.response.status === 404) {
            invoiceData.value = undefined
          }
        },
      )

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      paymentDetails,
      shipments,
      shippingAddress,
      invoice,
      printInvoice,
    }
  },
  methods: {
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'America/Mexico_City',
      }
      const parsedDate = new Date(date)
      const formatter = new Intl.DateTimeFormat('es', options)
      return formatter.format(parsedDate)
    },
    getOrderInformation() {
      store.dispatch('orders/show', { orderId: router.currentRoute.params.id })
        .then(
          response => {
            this.invoiceData = response.orders
            this.paymentDetails = response.payment
            this.shipments = response.shipments
            this.shippingAddress = response.shippingAddress
            this.invoice = response.invoice
          },
          error => {
            if (error.response.status === 404) {
              this.invoiceData = undefined
            }
          },
        )
    },
    createInvoice() {
      store.dispatch('orders/createInvoice', { orderId: this.invoiceData.id })
    },
    closeIssueInvoiceSidebar() {
      this.getOrderInformation()
      this.issueInvoiceToggle = true
      this.issueInvoiceToggle = false
    },
    showIssueInvoiceSidebar() {
      this.getOrderInformation()
      this.issueInvoiceToggle = false
      this.issueInvoiceToggle = true
    },
    downloadInvoice() {
      this.submitting = true
      const self = this
      store.dispatch('orders/downloadInvoice', { orderId: this.invoiceData.id }).then(
        response => {
          const element = document.createElement('a')
          element.setAttribute('href', `data:text/xml;charset=utf-8,${encodeURIComponent(response)}`)
          element.setAttribute('download', `${this.invoiceData.number}.xml`)

          element.style.display = 'none'
          document.body.appendChild(element)

          element.click()

          document.body.removeChild(element)
          self.submitting = false
        },
        () => {
          self.submitting = false
        },
      )
    },
    downloadInvoicePdf() {
      window.open(`${API_URL}/order/${this.invoiceData.id}/invoice/download-pdf`, '_blank').focus()
    },
  },
}
</script>

<style lang="scss" scoped>
.invoice-date-wrapper {
  .invoice-date-title {
    min-width: 11rem!important;
  }
}
.invoice-title {
  font-size: 1.6rem!important;
}
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
   .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
